import React, { useState, useEffect, useRef } from "react";
import parse from "html-react-parser";
import VisibilitySensor from "react-visibility-sensor";
import "../../../sass/components/reuseable_components/_services-staff-block.scss";
import Img from "gatsby-image";
import { Link } from "gatsby";

const ServicesStaffBlock = (data) => {
  let classList = ["services-staff-block", "section"];

  function inviewOnChange(isVisible) {
    setIsInview(isVisible);
  }

  const [isInview, setIsInview] = useState(false);

  return (
    <VisibilitySensor
      partialVisibility={true}
      offset={{ bottom: 120 }}
      active={!isInview}
      onChange={inviewOnChange}>
      {({ isVisible }) => (
        <section
          className={`${classList.join(" ")} ${isInview ? "is_inview" : ""}`}
          data-bg-shape="show_shape">
          <div className="wrapper">
            <h2 className="heading">{data.servicesStaffBlockHeading}</h2>
            <div className="text"> {parse(data.servicesStaffBlockIntroText)}</div>
            <div className="staff-members">
              {data.servicesStaffRepeater.map((staffMember, index) => {
                return (
                  <Link
                    // to={`/team`}
                    to={`/team?staffMember=${staffMember.staffMember.slug}`}
                    className="staff-member"
                    key={`staff_member_${index}`}>
                    <div
                      className={`image-wrapper color-${staffMember.staffMember.acf_staff_member.colour}`}>
                      <Img
                        fluid={
                          staffMember.staffMember.acf_staff_member.image.localFile.childImageSharp
                            .fluid
                        }
                        alt={staffMember.staffMember.acf_staff_member.image.altText}
                      />
                    </div>
                    <div className="text">
                      <h3 className="heading">{staffMember.staffMember.title}</h3>
                      <p>{parse(staffMember.text)}</p>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
          <Link to="/team" className="button button-capsule">
            Find out more about our team
          </Link>
        </section>
      )}
    </VisibilitySensor>
  );
};

export default ServicesStaffBlock;
